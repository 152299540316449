<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @export-click="exportClick"
      @refresh-table="handleResetTable"
      @refresh-table-height="handleResetTableHeight"
    />
    <RoleList ref="roleListRef"></RoleList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import RoleList from "./components/RoleList";
import { downloadFile } from "@/utils/common";
// import downLoadFile from "@/utils/downLoadFile"
import { useGlobalPropertyHook } from "@/hooks/common";
// import moment from "moment"

export default defineComponent({
  name: "exportRoleReview",
  components: {
    RoleList,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const roleListRef = ref();
    const handleSearch = (searchParams) => {
      roleListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      roleListRef.value.doSearch();
    };

    const exportClick = (searchParams) => {
      $api.roleReviewExport(searchParams).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const handleResetTableHeight = () => {
      roleListRef.value.getSize();
    };
    return {
      roleListRef,
      handleSearch,
      handleResetTable,
      exportClick,
      handleResetTableHeight
    };
  },
});
</script>
